import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[wmaHasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private viewContainer = inject(ViewContainerRef);
  private templateRef = inject<TemplateRef<unknown>>(TemplateRef);
  private authService = inject(AuthService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private hasView = false;
  private destroy$ = new Subject<boolean>();
  private permissions: string[] = [];
  // TODO(amadou): Attempt to remove "!" on property
  private requiredPermission!: string;

  private getPermissions(): Observable<string[]> {
    return this.authService.getAccessTokenSilently().pipe(
      map((token) => {
        const jwtHelper = new JwtHelperService();
        return jwtHelper.decodeToken(token).permissions;
      }),
    );
  }

  ngOnInit(): void {
    this.getPermissions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((permissions) => {
        this.permissions = permissions;
        this.checkPermission();
      });
  }

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set wmaHasPermission(permission: string) {
    if (this.requiredPermission !== permission) {
      this.requiredPermission = permission;
      this.checkPermission();
    }
  }

  private createView(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
    // We call `markForCheck` to instruct Angular to run a change detection
    // This makes the `hasPermission` directive work inside a component
    // that uses the `ChangeDetectionStrategy.OnPush` strategy
    this.changeDetectorRef.markForCheck();
  }

  private clearView(): void {
    this.viewContainer.clear();
    this.hasView = false;
    // We call `markForCheck` to instruct Angular to run a change detection
    // This makes the `hasPermission` directive work inside a component
    // that uses the `ChangeDetectionStrategy.OnPush` strategy
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private checkPermission(): void {
    if (
      !this.hasView &&
      (this.requiredPermission === '' || this.permissions.includes(this.requiredPermission))
    ) {
      this.createView();
    } else if (
      this.hasView &&
      !(this.requiredPermission === '' || this.permissions.includes(this.requiredPermission))
    ) {
      this.clearView();
    }
  }
}
